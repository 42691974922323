<template>
    <div class="section" key="driversLicence">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3>Sind Sie auf Ihren Führerschein angewiesen?</h3>
            <p>Gibt es besondere Gründe, die Sie von Ihrem Führerschein abhängig machen? Dies können berufliche oder wichtige private Gründe sein.</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.driversLicense" name="radio-8" value="Ja, beruflich" id="question-15-1">
            <label for="question-15-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-person-job.svg"></span><span>Ja, beruflich</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.driversLicense" name="radio-8" value="Ja, sonstiges" id="question-15-2">
            <label for="question-15-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-taxi-license.svg"></span><span>Ja, sonstiges</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.driversLicense" name="radio-8" value="Nein" id="question-15-3">
            <label for="question-15-3" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-cancel.svg"></span><span>Nein bin ich nicht</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.driversLicense" name="radio-8" value="unsicher" id="question-15-4">
            <label for="question-15-4" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-ask-question.svg"></span><span>Ich bin mir unsicher</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'DriversLicense',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>